import { FC, PropsWithChildren, ReactNode, StrictMode, useCallback, useState } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import deLocale from 'date-fns/locale/de';
import { SnackbarProvider } from 'notistack';
import { render } from 'react-dom';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import './index.css';
import App from './App';
import { AppState, Auth0Provider } from './providers/auth0';
import { StartPage } from './startPage';
import { defaultTheme } from './theme/default';
import { useAsyncEffect } from '~/hooks/asyncEffect';
import { Webcam } from '~/webcam/webcam';

type AuthProps = PropsWithChildren<ReactNode>;

interface Config {
  authentication: AuthConfig;
}

interface AuthConfig {
  domain: string;
  clientId: string;
  connection: string;
}

const Auth: FC<AuthProps> = (props: AuthProps): JSX.Element => {
  const navigate = useNavigate();
  const onRedirectCallback = useCallback(
    async (appState: AppState): Promise<void> => {
      if (appState && appState.locationPathname) {
        await navigate(appState.locationPathname);
        return;
      }
      await navigate('/');
    },
    [navigate]
  );

  const [isLoading, setLoading] = useState<boolean>(true);
  const [config, setConfig] = useState<AuthConfig>({} as AuthConfig);

  useAsyncEffect(async () => {
    const response = await fetch('/config.json');
    const configJson = (await response.json()) as Config;
    setConfig(configJson.authentication);
    setLoading(false);
  }, []);

  return (
    <Auth0Provider
      clientOptions={{
        isLoading: isLoading,
        client_id: config.clientId,
        domain: config.domain,
        connection: config.connection,
        audience: 'https://apis.zeromberto.de',
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          preventDuplicate={true}
        >
          {props.children}
        </SnackbarProvider>
      </LocalizationProvider>
    </Auth0Provider>
  );
};

render(
  <StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <Auth>
          <Routes>
            <Route path='/' element={<App />}>
              <Route index element={<StartPage />} />
              <Route path='webcam' element={<Webcam />} />
            </Route>
          </Routes>
        </Auth>
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);
