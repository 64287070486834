import React, { FC, PropsWithChildren } from 'react';
import { Card, CardContent, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

type PageViewCardProps = PropsWithChildren<React.ReactNode>;

export const PageViewCard: FC<PageViewCardProps> = (props: PageViewCardProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card data-test-id={'page-view-card'}>
        <CardContent data-test-id={'page-view-card-content'}>{props.children}</CardContent>
      </Card>
    </div>
  );
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        paddingBottom: theme.spacing(9),
        [theme.breakpoints.up('lg')]: {
          paddingBottom: theme.spacing(10),
        },
        '& > .MuiCard-root, > .MuiCard-root > .MuiCardContent-root': {
          padding: 0,
        },
        '& > .MuiCard-root': {
          padding: theme.spacing('small', 'md'),
          [theme.breakpoints.up('lg')]: {
            padding: theme.spacing('large', 'md'),
          },
        },
      },
    }),
  { name: 'EpxPageViewCard' }
);
