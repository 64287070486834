import { FC, PropsWithChildren, ReactNode } from 'react';

import { createTheme, CssBaseline, StyledEngineProvider, Theme } from '@mui/material';
import { makeStyles, ThemeProvider } from '@mui/styles';

import { Header } from './header/header';

type LayoutProps = PropsWithChildren<ReactNode>;
export const Layout: FC<LayoutProps> = (props: LayoutProps): JSX.Element => {
  const theme = createTheme();
  const classes = useStyles(theme);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <main className={classes.root}>{props.children}</main>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const useStyles = makeStyles<Theme, Theme>(() => ({
  root: theme => ({
    flex: '1 0 auto',
    position: 'relative',
    overflowX: 'hidden',
    minHeight: '80vh',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '30px',
    paddingTop: '20px',
    [theme.breakpoints.up('lg')]: {
      paddingTop: '30px',
    },
  }),
}));
