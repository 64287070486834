import { DependencyList, MutableRefObject, useEffect, useRef } from 'react';

/**
 * Accepts a function that contains imperative, possibly effectful async code.
 * @param abortSignal A signal to pass to underlying components.
 * @param isMounted Indicating whether the component is mounted or not.
 */
export type AsyncEffectCallback = (abortSignal: AbortSignal, isMounted: MutableRefObject<boolean>) => Promise<void> | (() => Promise<void>);
export type CleanupFunction = () => void;

export function useAsyncEffect(effect: AsyncEffectCallback, deps?: DependencyList, cleanup?: CleanupFunction): void {
  const abortController = new AbortController();
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    effect(abortController.signal, isMountedRef);
    return (): void => {
      isMountedRef.current = false;
      abortController.abort();
      if (cleanup) {
        cleanup();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
