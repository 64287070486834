import { FC, useEffect, useState } from 'react';
import { AppBar, Avatar, Box, Button, IconButton, Theme, Toolbar, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ExitToApp } from '@mui/icons-material';
import { useAuth0 } from '~/providers/auth0';

export const Header: FC = (): JSX.Element => {
  const classes = styles();
  const theme = useTheme<Theme>();
  const small = useMediaQuery(theme.breakpoints.down('md'));
  const { isAuthenticated, logout, user } = useAuth0();

  const logoutUser = async (): Promise<void> => {
    await logout({
      returnTo: window.location.origin,
      federated: true,
    });
  };
  const [userName, setUserName] = useState('');

  useEffect(() => {
    if (user.name) {
      setUserName(user.name);
      return;
    }
    if (user.given_name) {
      setUserName(`${user.given_name} ${user.family_name}`);
      return;
    }
    if (user.email) {
      setUserName(user.email);
    }
  }, [user]);

  return (
    <AppBar color='primary' position={'sticky'} className={classes.root} data-testid={'header'}>
      <Toolbar className={classes.toolbar}>
        {small ? (
          <>
            {isAuthenticated && <Avatar alt={userName} src={user.picture} />}
            <div
              style={{
                left: '45%',
                position: 'absolute',
                justifyItems: 'center',
              }}
            >
              <Link to='/'>
                <img alt='logo' src='/logo192.png' className={classes.logo} />
              </Link>
            </div>
            <Box display='flex' flexGrow={1} />
            {isAuthenticated && (
              <IconButton sx={{ color: '#ddd' }} onClick={() => logoutUser()}>
                <ExitToApp />
              </IconButton>
            )}
          </>
        ) : (
          <>
            <div className={classes.logoContainer}>
              <Link to='/'>
                <img alt='logo' src='/logo192.png' className={classes.logo} />
              </Link>
            </div>
            <Box display='flex' flexGrow={1} />
            {isAuthenticated && (
              <>
                <Avatar alt={userName} src={user.picture} style={{ right: '5px' }} />
                <Typography component={'span'} display={{ xs: 'none', md: 'block' }}>
                  {userName}
                </Typography>
                <Button sx={{ color: '#ddd', justifyContent: 'end', left: '25px', marginRight: '20px' }} startIcon={<ExitToApp />} onClick={() => logoutUser()}>
                  Abmelden
                </Button>
              </>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

const styles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
    centerTitle: true,
    maxHeight: 128,
    [theme.breakpoints.down('md')]: {
      maxHeight: 70,
    },
  },
  toolbar: {
    width: '100%',
    height: 70,
    [theme.breakpoints.down('md')]: {
      maxHeight: 68,
    },
    flexGrow: 1,
    alignItems: 'center',
  },
  logoContainer: {
    left: '45%',
    position: 'absolute',
  },
  logo: {
    maxWidth: '230px',
    maxHeight: '64px',
    padding: '4px',
    width: 'auto',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: '1px',
      maxHeight: '44px',
      maxWidth: '100px',
    },
    verticalAlign: 'middle',
    justifyContent: 'center',
  },
}));
