import { useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { useAuth0 } from '~/providers/auth0';
import { useAsyncEffect } from '~/hooks/asyncEffect';

const apps = [
  {
    id: 'nextcloud',
    img: './nextcloud.png',
    title: 'Nextcloud',
    target: 'https://nextcloud.zeromberto.de',
  },
  {
    id: 'webcam',
    img: './tbr.png',
    title: 'Webcam Tennenbronn',
    target: '/webcam',
  },
  {
    id: 'smart-home',
    img: './home.png',
    title: 'Home Assistant',
    target: 'https://home.zeromberto.de',
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: theme.spacing(20),
  maxWidth: theme.spacing(20),
}));

export const StartPage = (): JSX.Element => {
  const classes = styles();
  const [allowedApps, setAllowedApps] = useState([] as string[]);
  const { getIdTokenClaims } = useAuth0();

  useAsyncEffect(async () => {
    const idTokenClaims = await getIdTokenClaims();
    if (idTokenClaims) {
      setAllowedApps(idTokenClaims['https://apps.zeromberto.de']);
    }
  }, [getIdTokenClaims]);

  return (
    <Grid container spacing={3} justifyContent='left' rowSpacing={2} columnSpacing={{ xs: 4, sm: 4, md: 5 }} className={classes.item}>
      {apps
        .filter(app => allowedApps.includes(app.id))
        .map(item => (
          <Grid key={item.id} item>
            <Item>
              <a key={item.id} href={item.target}>
                <img src={`${item.img}`} srcSet={`${item.img}`} alt={item.title} loading='lazy' className={classes.image} />
              </a>
            </Item>
          </Grid>
        ))}
    </Grid>
  );
};

const styles = makeStyles(() => ({
  item: {
    position: 'relative',
    padding: '10px',
  },
  image: {
    width: 'auto',
    height: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
  },
}));
