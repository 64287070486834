import React, { FC, PropsWithChildren } from 'react';

type ContainerProps = PropsWithChildren<React.ReactNode> & {
  className?: string;
};

export const WideContainer: FC<ContainerProps> = (props: ContainerProps): JSX.Element => {
  return (
    <div style={{ margin: '0 auto', maxWidth: '1192px' }} className={props.className}>
      {props.children}
    </div>
  );
};

export const Container: FC<ContainerProps> = (props: ContainerProps): JSX.Element => {
  return (
    <div style={{ margin: '0 auto', maxWidth: '840px' }} className={props.className}>
      {props.children}
    </div>
  );
};
