import path from 'path';
import React, { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useAsyncEffect } from '~/hooks/asyncEffect';
import { PageView } from '~/components/pageView';
import { useAuth0 } from '~/providers/auth0';

interface LatestResponse {
  path: string;
}

export const Webcam: FC = (): JSX.Element => {
  const base = 'webcam-data';
  const [latestImg, setLatestImg] = useState<string>('');
  const { getTokenSilently } = useAuth0();

  const queryLatest = async () => {
    const latest = await get<LatestResponse>(path.join('..', 'api', 'webcam', 'latest'));
    const url = window.location.origin + '/' + path.join(base, latest.path);
    await getImage(url);
  };

  async function getImage(url: string): Promise<void> {
    return getTokenSilently().then(token =>
      fetch(url, {
        headers: new Headers({
          Authorization: 'Bearer ' + token,
        }),
      }).then(response => {
        response
          .blob()
          .then(
            blob =>
              new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result as string);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          )
          .then(dataUrl => {
            console.log('RESULT:', dataUrl);
            setLatestImg(dataUrl);
          });
      })
    );
  }

  async function get<T>(url: string): Promise<T> {
    return getTokenSilently().then(token =>
      fetch(url, {
        headers: new Headers({
          Authorization: 'Bearer ' + token,
        }),
      }).then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
    );
  }

  useAsyncEffect(async () => {
    try {
      await queryLatest();
      setInterval(async () => {
        try {
          await queryLatest();
        } catch (e) {
          console.error(e);
        }
      }, 30000);
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <PageView
      title={<Typography variant={'h1'}>Webcam im Gersbach</Typography>}
      subTitle={
        <Typography variant={'h2'} component={'div'}>
          Bild wird jede Minute aktualisiert.
        </Typography>
      }
    >
      <Grid container justifyContent='center' paddingTop={'25px'}>
        <img
          src={latestImg}
          alt={'latest'}
          style={{
            maxWidth: '90%',
            maxHeight: '100%',
            height: 'auto',
            width: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            justifySelf: 'center',
          }}
        />
      </Grid>
    </PageView>
  );
};
