import React, { FC, PropsWithChildren, useCallback } from 'react';
import { Theme, Typography } from '@mui/material';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Container, WideContainer } from '../container';

type PageViewProps = PropsWithChildren<React.ReactNode> & {
  title?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  wide?: boolean;
  className?: string;
};

export const PageView: FC<PageViewProps> = (props: PageViewProps): JSX.Element => {
  const classes = useStyles();
  const view = useCallback(
    props => {
      return (
        <>
          <div className={classes.titleContainer}>
            <div className={classes.title}>
              {isString(props.title) ? (
                <Typography variant='h1' data-test-id={'page-view-title'}>
                  {props.title}
                </Typography>
              ) : (
                <>{props.title}</>
              )}
            </div>
            <div>
              {isString(props.subTitle) ? (
                <Typography variant='body1' data-test-id={'page-view-title-sub'} hidden={props.subTitle === undefined}>
                  {props.subTitle}
                </Typography>
              ) : (
                <>{props.subTitle}</>
              )}
            </div>
          </div>
          {props.children}
        </>
      );
    },
    [props]
  );
  return (
    <div className={classNames(classes.root, props.className)}>
      {props.wide ? <WideContainer>{view(props)}</WideContainer> : <Container>{view(props)}</Container>}
    </div>
  );
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    title: {
      paddingBottom: theme.spacing('small', 'xl'),
      [theme.breakpoints.up('lg')]: {
        paddingBottom: theme.spacing('large', 'xl'),
      },
    },
    titleContainer: {
      paddingBottom: theme.spacing('small', 'xxl'),
      [theme.breakpoints.up('lg')]: {
        paddingBottom: theme.spacing('large', 'xxl'),
      },
    },
  }),
  { name: 'EpxPageView' }
);

function isString(val: unknown): val is string {
  return typeof val === 'string';
}
