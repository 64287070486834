import React, { FC, PropsWithChildren } from 'react';
import { CardContent, Typography, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

type PageViewCardTitleProps = PropsWithChildren<React.ReactNode> & {
  title: string | React.ReactNode;
};

export const PageViewCardTitle: FC<PageViewCardTitleProps> = (props: PageViewCardTitleProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CardContent data-test-id={'page-card-title-content'}>
        <Typography variant={'h2'} data-test-id={'page-card-title-content-value'}>
          {props.title}
        </Typography>
        {props.children}
      </CardContent>
    </div>
  );
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        '& > .MuiCardContent-root': {
          display: 'flex',
          width: '100%',
          position: 'relative',
          padding: 0,
          paddingBottom: theme.spacing(4),
          [theme.breakpoints.up('lg')]: {
            paddingBottom: theme.spacing(6),
          },
        },
      },
    }),
  { name: 'EpxPageViewCardTitle' }
);
