import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { ConditionalDisplay } from './components/conditionalDisplay';
import { Layout } from './layout/layout';
import { useAuth0 } from './providers/auth0';
import { useAsyncEffect } from '~/hooks/asyncEffect';

const App: FC = (): JSX.Element => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useAsyncEffect(async () => {
    if (!isAuthenticated) {
      await loginWithRedirect();
      return;
    }
  }, [isAuthenticated]);

  return (
    <ConditionalDisplay visible={isAuthenticated}>
      <Layout>
        <Outlet />
      </Layout>
    </ConditionalDisplay>
  );
};

export default App;
