import { green, grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const defaultTheme = createTheme({
  typography: {
    fontSize: 14,
    htmlFontSize: 14,
    h1: {
      fontWeight: 'bold',
      fontSize: '34px',
      lineHeight: '40px',
      small: {
        fontSize: '28px',
        lineHeight: '30px',
      },
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '30px',
      small: {
        fontSize: '16px',
        lineHeight: '27px',
      },
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '20px',
      small: {
        fontSize: '14px',
        lineHeight: '23px',
      },
    },
    body1: {
      fontSize: '15px',
      lineHeight: '24px',
      small: {
        fontSize: '15px',
        lineHeight: '24px',
      },
    },
    body2: {
      fontSize: '15px',
      lineHeight: '24px',
      small: {
        fontSize: '14px',
        lineHeight: '17px',
      },
    },
  },
  palette: {
    text: {},
    primary: {
      main: grey[900],
    },
    secondary: {
      main: green[500],
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingBottom: '0px',
        },
        title: {
          fontSize: 24,
          fontWeight: 'bold',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          justifyContent: 'right',
        },
      },
    },
  },
});
