import { createContext, FC, PropsWithChildren, ReactNode, useContext, useState } from 'react';

import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  IdToken,
  LogoutOptions,
  RedirectLoginOptions as Auth0RedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import { PopupConfigOptions, PopupLoginOptions, User } from '@auth0/auth0-spa-js/dist/typings/global';
import { useAsyncEffect } from '~/hooks/asyncEffect';

const DEFAULT_REDIRECT_CALLBACK = (): void => window.history.replaceState({}, document.title, window.location.pathname);
const uninitializedPromise = <T,>(): Promise<T> => Promise.reject('auth0 not initialized');

export interface AppState {
  locationPathname?: string;

  [key: string]: unknown;
}

type RedirectLoginOptions = Omit<Auth0RedirectLoginOptions, 'appState'> & {
  appState?: AppState;
};

type Auth0ProviderProps = PropsWithChildren<ReactNode> & {
  onRedirectCallback: (appState: AppState) => void;
  clientOptions: Auth0ClientOptions;
};

interface Auth0ContextState {
  isAuthenticated: boolean;
  user: User;
  isLoading: boolean;
  isPopupOpen: boolean;
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
  loginWithPopup: (options?: PopupLoginOptions, config?: PopupConfigOptions) => Promise<void>;
  handleRedirectCallback: () => Promise<void>;
  getIdTokenClaims: (options?: GetIdTokenClaimsOptions) => Promise<IdToken | undefined>;
  getTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>;
  getTokenWithPopup: (options?: GetTokenWithPopupOptions, config?: PopupConfigOptions) => Promise<string>;
  logout: (options?: LogoutOptions) => Promise<void>;
}

const defaultState: Auth0ContextState = {
  isAuthenticated: false,
  user: {
    email: '',
    email_verified: false,
    family_name: '',
    given_name: '',
    locale: '',
    name: '',
    nickname: '',
    picture: '',
    sub: '',
    updated_at: '',
  },
  isLoading: true,
  isPopupOpen: false,
  loginWithRedirect: uninitializedPromise,
  loginWithPopup: uninitializedPromise,
  handleRedirectCallback: uninitializedPromise,
  getIdTokenClaims: uninitializedPromise,
  getTokenSilently: uninitializedPromise,
  getTokenWithPopup: uninitializedPromise,
  logout: uninitializedPromise,
};
const auth0Context = createContext<Auth0ContextState>(defaultState);
export const useAuth0 = (): Auth0ContextState => useContext(auth0Context);

export const Auth0Provider: FC<Auth0ProviderProps> = (props: Auth0ProviderProps): JSX.Element => {
  const [client, setClient] = useState<Auth0Client | undefined>();
  const [isAuthenticated, setIsAuthenticated] = useState(defaultState.isAuthenticated);
  const [user, setUser] = useState<User>(defaultState.user);
  const [isLoading, setIsLoading] = useState(defaultState.isLoading);
  const [isPopupOpen, setIsPopupOpen] = useState(defaultState.isPopupOpen);

  useAsyncEffect(async () => {
    if (props.clientOptions.isLoading) return;
    const client = await createAuth0Client(props.clientOptions);
    setClient(client);

    if (
      window.location.search.includes('code=') &&
      //auth0 email validation link requires no RedirectCallback
      !window.location.search.includes('supportSignUp') &&
      !window.location.search.includes('supportForgotPassword')
    ) {
      const { appState } = await client.handleRedirectCallback();
      props.onRedirectCallback(appState);
    }

    await setAuthenticatedUser(client);

    setIsLoading(false);
  }, [props.clientOptions.isLoading]);

  async function setAuthenticatedUser(client: Auth0Client): Promise<void> {
    const isAuthenticated = await client.isAuthenticated();
    setIsAuthenticated(isAuthenticated);

    if (isAuthenticated) {
      const user = await client.getUser();
      if (user) setUser(user);
    }
  }

  async function loginWithPopup(options?: PopupLoginOptions, config?: PopupConfigOptions): Promise<void> {
    if (!client) {
      return uninitializedPromise();
    }
    setIsPopupOpen(true);
    try {
      await client.loginWithPopup(options, config);
    } finally {
      setIsPopupOpen(false);
    }
    await setAuthenticatedUser(client);
  }

  async function handleRedirectCallback(): Promise<void> {
    if (!client) {
      return uninitializedPromise();
    }
    setIsLoading(true);
    await client.handleRedirectCallback();
    await setAuthenticatedUser(client);
    setIsLoading(false);
  }

  async function loginWithRedirect(options?: RedirectLoginOptions): Promise<void> {
    if (!client) {
      return uninitializedPromise();
    }

    if (!options) {
      options = {};
    }

    if (!options.appState) {
      options.appState = {
        locationPathname: window.location.pathname,
      };
    }

    await client.loginWithRedirect(options);
  }

  return (
    <auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        isLoading,
        isPopupOpen,
        loginWithPopup,
        handleRedirectCallback,
        loginWithRedirect,
        getIdTokenClaims: (opts?) => (client ? client.getIdTokenClaims(opts) : uninitializedPromise()),
        getTokenSilently: (opts?) => (client ? client.getTokenSilently(opts) : uninitializedPromise()),
        getTokenWithPopup: (opts?, cfg?) => (client ? client.getTokenWithPopup(opts, cfg) : uninitializedPromise()),
        logout: (opts?) => {
          if (!client) {
            return uninitializedPromise();
          }
          client.logout(opts);
          return Promise.resolve();
        },
      }}
    >
      {isLoading ? null : props.children}
    </auth0Context.Provider>
  );
};

Auth0Provider.defaultProps = {
  onRedirectCallback: DEFAULT_REDIRECT_CALLBACK,
};
